<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" clearable/>
            <el-select class="filter-item" v-model="listQuery.status" placeholder="审核状态" style="width: 140px" clearable>
                <el-option label="待审核" :value="0"></el-option>
                <el-option label="已通过" :value="1"></el-option>
                <el-option label="已驳回" :value="2"></el-option>
            </el-select>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </div>
        <div class="filter-container">
            <el-button-group>
                <el-button type="success" size="small" @click="handleBatchExamine(1)" :disabled="ids.length <= 0">批量通过</el-button>
                <el-button type="danger" size="small" @click="handleBatchExamine(2)" :disabled="ids.length <= 0">批量拒绝</el-button>
            </el-button-group>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column label="申请人" min-width="110" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <img :src="scope.row.user.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                        <span>{{ scope.row.user.name }}</span>
                        <span>{{ scope.row.user.phone }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="收款方式" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.payment_method == '1'" type="primary">微信零钱</el-tag>
                    <el-tag v-else-if="scope.row.payment_method == '2'" type="success">银行卡</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="提现金额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.amount }}
                </template>
            </el-table-column>

            <el-table-column label="开户行" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.bank_name }}
                </template>
            </el-table-column>
            <el-table-column label="银行卡号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.bank_no }}
                </template>
            </el-table-column>
            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column label="审核状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.status == 0">待审核</el-tag>
                    <el-tag type="success" v-else-if="scope.row.status == 1">已通过</el-tag>
                    <template v-else-if="scope.row.status == 2">
                        <el-tag type="danger">已驳回</el-tag>
                        <div>原因:{{scope.row.refuse_reason}}</div>
                    </template>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" min-width="200">
                <template slot-scope="scope">
                    <i class="el-icon-time" />
                    <span>{{ scope.row.created_at }}</span>
                </template>
            </el-table-column>
<!--            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">-->
<!--                <template slot-scope="scope">-->
<!--                    <div>-->
<!--                        <el-button v-if="scope.row.status == 0" type="warning" size="mini" @click="handleExamine(scope.row)">审核</el-button>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 审核 -->
        <el-dialog title="提现审核" :visible.sync="dialogExamineVisible" width="60%" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="审核结果" prop="status">
                    <el-radio-group v-model="form.status" size="small">
                        <el-radio :label="1" border>通过</el-radio>
                        <el-radio :label="2" border>驳回</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="form.status == 2" label="驳回原因" prop="refuse_reason" :rules="[{required:true,message:'驳回原因不能为空'}]">
                    <el-input type="textarea" :rows="4" v-model="form.refuse_reason"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogExamineVisible = false">取消</el-button>
                <el-button type="primary" @click="saveExamineData" :loading="btnLoading"
                >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 审核 -->

        <!-- 失败提示 -->
        <el-dialog title="批量失败原因" :visible.sync="dialogErrorVisible" width="60%" :close-on-click-modal="false" @close="handleCloseError">
            <div style="color:#f56c6c;overflow-y:scroll;height:400px" v-if="errArr.length > 0">
                <p style="font-size: 16px;">失败数：{{errArr.length}}</p>
                <p v-for="(item,index) in errArr" :key="index" >
                    <span style="margin-right: 20px;">id：{{item.request.id}}</span>
                    <span>失败信息：{{item.msg}}</span>
                </p>
            </div>
        </el-dialog>
        <!-- 失败提示 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    keyword: '',
                    status: '',
                },
                // 审核
                dialogExamineVisible: false,
                btnLoading: false,
                form:{
                    ids: '',
                    status: 1,
                    refuse_reason: '',
                },
                // 多选
                ids: [],
                // 批量失败数据
                dialogErrorVisible: false,
                errArr: [],
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/sendman/withdrawList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 审核
            resetForm() {
                this.form = {
                    ids: '',
                    status: 1,
                    refuse_reason: '',
                }
                this.btnLoading = false
            },
            handleExamine(row){
                this.resetForm()
                this.form.id = row.id
                this.dialogExamineVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveExamineData(){
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/sendman/batchWithdrawExamine',
                            method: 'post',
                            data: this.form
                        }).then(response => {
                            this.btnLoading = false
                            this.dialogExamineVisible = false
                            this.$message({
                                type: 'success',
                                message:  `成功数：${response.data.success.length} 失败数：${response.data.fail.length}`
                            });
                            this.getList()
                            // 显示失败原因
                            if (response.data.fail.length > 0) {
                                this.errArr = response.data.fail;
                                this.dialogErrorVisible = true
                            }
                        }).catch((err) => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 多选
            handleSelectionChange(val) {
                let ids = [];
                val.forEach(element => {
                    ids.push(element.id);
                });
                this.ids = ids;
            },
            // 批量审核
            handleBatchExamine(status){
                this.resetForm()
                this.form.ids = this.ids
                this.form.status = status
                this.dialogExamineVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            // 关闭失败原因
            handleCloseError(){
                this.errArr = [];
            }
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
